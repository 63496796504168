import React from 'react'
import Img from 'gatsby-image'

import { graphql } from 'gatsby'
import { StaticQuery } from 'gatsby'

import slBottleCSS from './bottle.module.css'

const slBottle = () => (
  <StaticQuery
    query={graphql`
      query slbottleQ {
        bouteilleun: file(
          name: { eq: "bouteilleun" }
          ext: { eq: ".jpg" }
          relativePath: { regex: "/stilllife/" }
        ) {
          name
          childImageSharp {
            fluid(maxWidth: 1000, quality: 70) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        bouteilledeux: file(
          name: { eq: "bouteilledeux" }
          ext: { eq: ".jpg" }
          relativePath: { regex: "/stilllife/" }
        ) {
          name
          childImageSharp {
            fluid(maxWidth: 1000, quality: 70) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        bouteilletrois: file(
          name: { eq: "bouteilletrois" }
          ext: { eq: ".jpg" }
          relativePath: { regex: "/stilllife/" }
        ) {
          name
          childImageSharp {
            fluid(maxWidth: 1000, quality: 70) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={({ bouteilleun, bouteilledeux, bouteilletrois }) => (
      <section className={slBottleCSS.wrapper}>
        <Img
          className={slBottleCSS.bouteilleun}
          fluid={bouteilleun.childImageSharp.fluid}
        />
        <Img
          className={slBottleCSS.bouteilledeux}
          fluid={bouteilledeux.childImageSharp.fluid}
        />
        <Img
          className={slBottleCSS.bouteilletrois}
          fluid={bouteilletrois.childImageSharp.fluid}
        />
      </section>
    )}
  />
)

export default slBottle
