import React from 'react'
import Img from 'gatsby-image'

import { graphql } from 'gatsby'
import { StaticQuery } from 'gatsby'

import slPaperplaneCSS from './paperplane.module.css'

const slPaperPlane = () => (
  <StaticQuery
    query={graphql`
      query slPaperPlaneQ {
        planeblue: file(
          name: { eq: "paperplaneBlue" }
          ext: { eq: ".jpg" }
          relativePath: { regex: "/stilllife/" }
        ) {
          name
          childImageSharp {
            fluid(maxWidth: 1000, quality: 70) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        planered: file(
          name: { eq: "paperplaneRed" }
          ext: { eq: ".jpg" }
          relativePath: { regex: "/stilllife/" }
        ) {
          name
          childImageSharp {
            fluid(maxWidth: 1000, quality: 70) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        planegreen: file(
          name: { eq: "paperplaneGreen" }
          ext: { eq: ".jpg" }
          relativePath: { regex: "/stilllife/" }
        ) {
          name
          childImageSharp {
            fluid(maxWidth: 1000, quality: 70) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={({ planeblue, planered, planegreen }) => (
      <section className={slPaperplaneCSS.wrapper}>
        <Img
          className={slPaperplaneCSS.planeblue}
          fluid={planeblue.childImageSharp.fluid}
        />
        <Img
          className={slPaperplaneCSS.planered}
          fluid={planered.childImageSharp.fluid}
        />
        <Img
          className={slPaperplaneCSS.planegreen}
          fluid={planegreen.childImageSharp.fluid}
        />
      </section>
    )}
  />
)

export default slPaperPlane
