import React from 'react'
import { Helmet } from 'react-helmet'

const ScrollHelmet = ({ catname }) => (
  <Helmet
    title={[catname, '- Rémi Carayon'].join(' ')}
    bodyAttributes={{
      class: 'scroll',
    }}
    defer={false}
  >
    <meta property="og:title" content={catname} />
  </Helmet>
)

export default ScrollHelmet
