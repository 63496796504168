import React from 'react'
import Img from 'gatsby-image'

import { graphql } from 'gatsby'
import { StaticQuery } from 'gatsby'

import slAnimalCSS from './animal.module.css'

const slAnimal = () => (
  <StaticQuery
    query={graphql`
      query slAnimalQ {
        zebre: file(
          name: { eq: "zebre" }
          ext: { eq: ".jpg" }
          relativePath: { regex: "/stilllife/" }
        ) {
          name
          childImageSharp {
            fluid(maxWidth: 1000, quality: 70) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        flamant: file(
          name: { eq: "flamant" }
          ext: { eq: ".jpg" }
          relativePath: { regex: "/stilllife/" }
        ) {
          name
          childImageSharp {
            fluid(maxWidth: 1000, quality: 70) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        chaton: file(
          name: { eq: "chaton" }
          ext: { eq: ".jpg" }
          relativePath: { regex: "/stilllife/" }
        ) {
          name
          childImageSharp {
            fluid(maxWidth: 1000, quality: 70) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={({ chaton, zebre, flamant }) => (
      <section className={slAnimalCSS.wrapper}>
        <Img
          className={slAnimalCSS.chaton}
          fluid={chaton.childImageSharp.fluid}
        />
        <Img
          className={slAnimalCSS.zebre}
          fluid={zebre.childImageSharp.fluid}
        />
        <Img
          className={slAnimalCSS.flamant}
          fluid={flamant.childImageSharp.fluid}
        />
      </section>
    )}
  />
)

export default slAnimal
