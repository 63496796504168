import React from 'react'
import { graphql } from 'gatsby'
import ScrollHelmet from '../components/catscroll/scrollhelmet'

import Layout from '../components/layout'
import Content from '../components/content'
import Top from '../components/menu/top'

import SlPaperplane from '../components/catscroll/stillife/paperplane'
import SlBottle from '../components/catscroll/stillife/bottle'
import SlAnimal from '../components/catscroll/stillife/animal'

const catname = 'Still Life'

const StillPage = () => {
  return (
    <Layout place="scroll">
      <ScrollHelmet catname={catname} />
      <Top />
      <Content>
        <SlPaperplane />
        <SlBottle />
        <SlAnimal />
      </Content>
    </Layout>
  )
}

export default StillPage

export const pageQuery = graphql`
  query allstillimages {
    allFile(
      filter: {
        absolutePath: { regex: "/cat/stilllife/paperplane/" }
        ext: { eq: ".jpg" }
      }
      sort: { fields: name, order: ASC }
    ) {
      totalCount
      edges {
        node {
          name
          id
          childImageSharp {
            fluid(maxHeight: 1200, fit: CONTAIN, quality: 70) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
